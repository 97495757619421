/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum UserCommunicationType {
    COLLECT_PATIENT_INFO_PAST_SESSION = 'COLLECT_PATIENT_INFO_PAST_SESSION',
    COLLECT_PATIENT_INFO_FIRST_ATTEMPT = 'COLLECT_PATIENT_INFO_FIRST_ATTEMPT',
    COLLECT_PATIENT_INFO_SECOND_ATTEMPT = 'COLLECT_PATIENT_INFO_SECOND_ATTEMPT',
    COLLECT_PATIENT_INFO_THIRD_ATTEMPT = 'COLLECT_PATIENT_INFO_THIRD_ATTEMPT',
    COLLECT_PATIENT_INFO_FINAL_ATTEMPT = 'COLLECT_PATIENT_INFO_FINAL_ATTEMPT',
    SESSION_REMINDER = 'SESSION_REMINDER',
    FIRST_SESSION_FOLLOWUP = 'FIRST_SESSION_FOLLOWUP',
    PROVIDER_COLLECT_PATIENT_INFO = 'PROVIDER_COLLECT_PATIENT_INFO',
    PATIENT_PAYMENT_FAILED = 'PATIENT_PAYMENT_FAILED',
    PROVIDER_PATIENT_PAYMENT_FAILED_AUTOMATIC = 'PROVIDER_PATIENT_PAYMENT_FAILED_AUTOMATIC',
    PROVIDER_PATIENT_PAYMENT_FAILED_MANUAL = 'PROVIDER_PATIENT_PAYMENT_FAILED_MANUAL',
    PATIENT_PRE_AUTHORIZED_CHARGE_FAILED = 'PATIENT_PRE_AUTHORIZED_CHARGE_FAILED',
    PATIENT_PRE_AUTHORIZED_CHARGE_SUCCEEDED = 'PATIENT_PRE_AUTHORIZED_CHARGE_SUCCEEDED',
    PATIENT_PAYMENT_FAILED_REMOVED = 'PATIENT_PAYMENT_FAILED_REMOVED',
    ABANDONED_CART_FIRST_ATTEMPT = 'ABANDONED_CART_FIRST_ATTEMPT',
    ABANDONED_CART_SECOND_ATTEMPT = 'ABANDONED_CART_SECOND_ATTEMPT',
    ABANDONED_CART_THIRD_ATTEMPT = 'ABANDONED_CART_THIRD_ATTEMPT',
    PROVIDER_ACCOUNT_INVITE = 'PROVIDER_ACCOUNT_INVITE',
    PROVIDER_TASKS_STATUS_UPDATE = 'PROVIDER_TASKS_STATUS_UPDATE',
    PROVIDER_TASKS_OVERVIEW = 'PROVIDER_TASKS_OVERVIEW',
    PROVIDER_TASKS_ACTION_NEEDED_HIGH_PRIORITY = 'PROVIDER_TASKS_ACTION_NEEDED_HIGH_PRIORITY',
    PROVIDER_QUESTIONNAIRE_REMINDER = 'PROVIDER_QUESTIONNAIRE_REMINDER',
    PROVIDER_GO_LIVE = 'PROVIDER_GO_LIVE',
    PROVIDER_PAYER_CREDENTIALED = 'PROVIDER_PAYER_CREDENTIALED',
    PROVIDER_PAYMENT_READY = 'PROVIDER_PAYMENT_READY',
    PROVIDER_VERIFICATION_REQUIREMENTS_REMINDER = 'PROVIDER_VERIFICATION_REQUIREMENTS_REMINDER',
    PATIENT_ACCOUNT_INVITE = 'PATIENT_ACCOUNT_INVITE',
    PATIENT_PORTING_REMINDER = 'PATIENT_PORTING_REMINDER',
    SMS_PHYPA_FIRST_SESSION_REMINDER = 'SMS_PHYPA_FIRST_SESSION_REMINDER',
    PATIENT_OUTCOME_QUESTIONNAIRE_REQUEST = 'PATIENT_OUTCOME_QUESTIONNAIRE_REQUEST',
    PATIENT_OUTCOME_QUESTIONNAIRE_REMINDER = 'PATIENT_OUTCOME_QUESTIONNAIRE_REMINDER',
    NEW_PROVIDER_ACTIVATION_REMINDER = 'NEW_PROVIDER_ACTIVATION_REMINDER',
    PATIENT_NPS_SURVEY = 'PATIENT_NPS_SURVEY',
    RECREDENTIALING_INTAKE_FORM = 'RECREDENTIALING_INTAKE_FORM',
    PROVIDER_UNCONFIRMED_SESSION_REMINDER = 'PROVIDER_UNCONFIRMED_SESSION_REMINDER',
    PROVIDER_RECREDENTIALED = 'PROVIDER_RECREDENTIALED',
    APPOINTMENT_CANCELED_TO_PROVIDER = 'APPOINTMENT_CANCELED_TO_PROVIDER',
    APPOINTMENT_CANCELED_TO_PATIENT = 'APPOINTMENT_CANCELED_TO_PATIENT',
    PROVIDER_PATIENT_FEEDBACK_REQUEST = 'PROVIDER_PATIENT_FEEDBACK_REQUEST',
    REQUEST_FOR_TRANSACTION_FEE_AGREEMENT = 'REQUEST_FOR_TRANSACTION_FEE_AGREEMENT',
    PATIENT_GENERAL_OON_WARNING = 'PATIENT_GENERAL_OON_WARNING',
    PROVIDER_CANCELED_APPOINTMENT_TO_PATIENT = 'PROVIDER_CANCELED_APPOINTMENT_TO_PATIENT',
    PATIENT_SUICIDAL_IDEATION_INITIAL_REACHOUT = 'PATIENT_SUICIDAL_IDEATION_INITIAL_REACHOUT',
    PATIENT_OPEN_BALANCE_REMINDER = 'PATIENT_OPEN_BALANCE_REMINDER',
    PATIENT_NEW_OPEN_BALANCE = 'PATIENT_NEW_OPEN_BALANCE',
    PROVIDER_AUDIT_DOCUMENT_REQUEST = 'PROVIDER_AUDIT_DOCUMENT_REQUEST',
    PROVIDER_AUDIT_DOCUMENT_REMEDIATION = 'PROVIDER_AUDIT_DOCUMENT_REMEDIATION',
    PROVIDER_TERMINATION_CONFIRMATION = 'PROVIDER_TERMINATION_CONFIRMATION',
    PROVIDER_AUDIT_DOCUMENT_REQUEST_REMINDER = 'PROVIDER_AUDIT_DOCUMENT_REQUEST_REMINDER',
    PROVIDER_AGREEMENT_ACCEPTANCE_CONFIRMATION = 'PROVIDER_AGREEMENT_ACCEPTANCE_CONFIRMATION',
    INTAKE_FORM_SENT_BY_PROVIDER = 'INTAKE_FORM_SENT_BY_PROVIDER',
    INTAKE_FORM_COMPLETED_BY_PATIENT = 'INTAKE_FORM_COMPLETED_BY_PATIENT',
    SEVEN_DAYS_IN_PRELIM_PRICING_MANUAL_VERIFICATION_SUGGESTION = 'SEVEN_DAYS_IN_PRELIM_PRICING_MANUAL_VERIFICATION_SUGGESTION',
    TOO_LONG_IN_OLD_DATA_PRELIM_PRICING_UPDATE = 'TOO_LONG_IN_OLD_DATA_PRELIM_PRICING_UPDATE',
    PATIENT_ENTERED_OLD_DATA_PRELIM_PRICING = 'PATIENT_ENTERED_OLD_DATA_PRELIM_PRICING',
    PATIENT_EXITED_OLD_DATA_PRELIM_PRICING = 'PATIENT_EXITED_OLD_DATA_PRELIM_PRICING',
    PROVIDER_ENTERED_OLD_DATA_PRELIM_PRICING = 'PROVIDER_ENTERED_OLD_DATA_PRELIM_PRICING',
    PROVIDER_EXITED_OLD_DATA_PRELIM_PRICING = 'PROVIDER_EXITED_OLD_DATA_PRELIM_PRICING',
    PATIENT_ENTERED_NO_DATA_PRELIM_PRICING = 'PATIENT_ENTERED_NO_DATA_PRELIM_PRICING',
    PATIENT_EXITED_NO_DATA_PRELIM_PRICING = 'PATIENT_EXITED_NO_DATA_PRELIM_PRICING',
    PROVIDER_ENTERED_NO_DATA_PRELIM_PRICING = 'PROVIDER_ENTERED_NO_DATA_PRELIM_PRICING',
    PROVIDER_EXITED_NO_DATA_PRELIM_PRICING = 'PROVIDER_EXITED_NO_DATA_PRELIM_PRICING',
    SEND_PROM_TO_PATIENT = 'SEND_PROM_TO_PATIENT',
    PROVIDER_SUICIDAL_IDEATION_NOTICE_FOLLOWING_PROM = 'PROVIDER_SUICIDAL_IDEATION_NOTICE_FOLLOWING_PROM',
    PROVIDER_POTENTIAL_DISABILITY_NOTICE_FOLLOWING_PROM = 'PROVIDER_POTENTIAL_DISABILITY_NOTICE_FOLLOWING_PROM',
    PROVIDER_PROM_DIGEST = 'PROVIDER_PROM_DIGEST',
    PATIENT_CHC_OUTAGE_TOO_LONG_IN_NO_DATA_PRELIM_PRICING = 'PATIENT_CHC_OUTAGE_TOO_LONG_IN_NO_DATA_PRELIM_PRICING',
    PATIENT_CHC_OUTAGE_TOO_LONG_IN_OLD_DATA_PRELIM_PRICING = 'PATIENT_CHC_OUTAGE_TOO_LONG_IN_OLD_DATA_PRELIM_PRICING',
    PROVIDER_CHC_OUTAGE_PATIENT_TOO_LONG_IN_PRELIM_PRICING = 'PROVIDER_CHC_OUTAGE_PATIENT_TOO_LONG_IN_PRELIM_PRICING',
    PATIENT_AUTOPAY_CHARGE_UPCOMING = 'PATIENT_AUTOPAY_CHARGE_UPCOMING',
    PROVIDER_AUDIT_DOCUMENT_REMEDIATION_REMINDER = 'PROVIDER_AUDIT_DOCUMENT_REMEDIATION_REMINDER',
    GROUP_PRACTICE_RECRED_REPORT = 'GROUP_PRACTICE_RECRED_REPORT',
    PATIENT_AUTOPAY_REMINDER = 'PATIENT_AUTOPAY_REMINDER',
    PATIENT_AUTOPAY_RECOUP_ISSUED_NOTICE = 'PATIENT_AUTOPAY_RECOUP_ISSUED_NOTICE',
    PATIENT_AUTOPAY_SCHEDULED_CONFIRMATION = 'PATIENT_AUTOPAY_SCHEDULED_CONFIRMATION',
    PATIENT_MESSAGE_NOTIFICATION = 'PATIENT_MESSAGE_NOTIFICATION',
    KAISER_PERMANENTE_REFERRAL_WELCOME = 'KAISER_PERMANENTE_REFERRAL_WELCOME',
    KAISER_PERMANENTE_REFERRAL_FIRST_NUDGE = 'KAISER_PERMANENTE_REFERRAL_FIRST_NUDGE',
    KAISER_PERMANENTE_REFERRAL_SECOND_NUDGE = 'KAISER_PERMANENTE_REFERRAL_SECOND_NUDGE',
    PATIENT_ALL_PAYMENT_METHODS_REMOVED = 'PATIENT_ALL_PAYMENT_METHODS_REMOVED',
    PATIENT_PAYMENT_METHOD_SWITCHED = 'PATIENT_PAYMENT_METHOD_SWITCHED',
    PATIENT_SECONDARY_PAYMENT_METHOD_REMOVED = 'PATIENT_SECONDARY_PAYMENT_METHOD_REMOVED'
}
