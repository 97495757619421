import { TablePagination } from '@mui/material';
import clsx from 'clsx';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PaymentRead } from '@headway/api/models/PaymentRead';
import { ProviderPaymentStatus } from '@headway/api/models/ProviderPaymentStatus';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { ListHeader } from '@headway/helix/ListHeader';
import { PageSection } from '@headway/helix/Page';
import { PageHeader } from '@headway/helix/PageHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { formatPriceFromCents } from '@headway/shared/utils/payments';

import { useGetUpcomingPaymentsForProvider } from 'hooks/useGetUpcomingPaymentsForProvider';
import { useProvider } from 'hooks/useProvider';

import { FormattedAppointmentInfo } from './FormattedAppointmentInfo';
import { PaymentFeesTooltip } from './PaymentInfoTooltip';

const DEFAULT_PAGE_SIZE = 25;

type PaymentAggregate = Array<[string, number]>;

export default function UpcomingTabWithIncentives() {
  const provider = useProvider();
  const navigate = useNavigate();
  const location = useLocation();

  const [paymentAggregate, setPaymentAggregate] = useState<
    PaymentAggregate | undefined
  >(undefined);
  const [pageSlice, setPageSlice] = useState<PaymentRead[]>([]);
  const [incentiveTotal, setIncentiveTotal] = useState<number>(0);

  const query = queryString.parse(location.search);
  const page = Number(query.page) || 0;
  const pageSize = Number(query.pageSize) || DEFAULT_PAGE_SIZE;

  const isPausedPaymentsFlagEnabled = useFlag('pausedPayments', false);

  const {
    data: upcomingPayments,
    isLoading,
    error,
  } = useGetUpcomingPaymentsForProvider({
    providerId: provider.id,
  });

  useEffect(() => {
    if (!upcomingPayments) {
      return;
    }

    let incentivesTotal = 0;
    const aggregate = upcomingPayments.reduce(
      (agg, payment) => {
        if (
          isPausedPaymentsFlagEnabled &&
          payment.providerPaymentStatus === ProviderPaymentStatus.UNDER_REVIEW
        ) {
          agg['Under review'] = agg['Under review'] || 0;
          agg['Under review'] += payment.amountCents;
        }

        const currentDate = moment().format('M/DD/YYYY`');
        const formatPaymentLandingDate = moment(
          payment.paymentLandingDate
        ).format('M/DD/YYYY');

        if (
          (payment.providerPaymentStatus === ProviderPaymentStatus.SCHEDULED &&
            formatPaymentLandingDate >= currentDate) ||
          payment.isProviderIncentiveBonus
        ) {
          agg[formatPaymentLandingDate] = agg[formatPaymentLandingDate] || 0;
          agg[formatPaymentLandingDate] += payment.amountCents;
        }

        if (payment.isProviderIncentiveBonus) {
          incentivesTotal += payment.amountCents;
        }

        return agg;
      },
      {} as { [key: string]: number }
    );

    const sortedPaymentAggregate = Object.entries(aggregate).sort(
      (a, b) =>
        moment(a[0]).toDate().getTime() - moment(b[0]).toDate().getTime()
    );

    setPaymentAggregate(sortedPaymentAggregate);
    setIncentiveTotal(incentivesTotal);
  }, [upcomingPayments, isPausedPaymentsFlagEnabled]);

  useEffect(() => {
    // sort upcoming payment by payment date then appointment start date
    const sortedUpcomingPayments = orderBy(
      upcomingPayments,
      [
        (item) =>
          item.providerPaymentStatus === ProviderPaymentStatus.UNDER_REVIEW
            ? 0
            : 1,
        (item) => new Date(item.paymentLandingDate),
        (item) => new Date(item.appointmentInfo!.appointmentStartAt),
        (item) => (item.isProviderIncentiveBonus ? 1 : 0),
      ],
      ['asc', 'desc', 'desc', 'asc']
    );

    setPageSlice(
      (sortedUpcomingPayments ?? [])?.slice(
        page * pageSize,
        page * pageSize + pageSize
      )
    );
  }, [upcomingPayments, page, pageSize]);

  function handlePageChange(_: unknown, newPage: number) {
    navigate({
      pathname: location.pathname,
      search: `?page=${newPage}&pageSize=${pageSize}`,
    });
  }

  function handleRowsPerPageChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    navigate({
      pathname: location.pathname,
      search: `?page=0&pageSize=${e.target.value}`,
    });
  }

  const hasUpcomingPayments =
    !!upcomingPayments && upcomingPayments.length !== 0;

  return (
    <div>
      <div className="p-2">
        <ListHeader>Upcoming payments</ListHeader>
        {hasUpcomingPayments && paymentAggregate && (
          <>
            <ul
              className="mb-5 flex list-none justify-around p-0"
              aria-labelledby="upcoming-totals"
            >
              {paymentAggregate.map(([date, amount]) => (
                <li
                  key={date}
                  className="mr-6 inline-block flex-grow"
                  aria-labelledby={`${date}-upcoming-total-date ${date}-upcoming-total-amount`}
                >
                  <div className="mb-1">
                    <SubBodyText>{date}</SubBodyText>
                  </div>
                  <div>
                    <PageHeader>
                      {formatPriceFromCents(amount, false)}
                    </PageHeader>
                  </div>
                  {incentiveTotal > 0 && date !== 'Under review' && (
                    <div className="mt-1 text-primary-blue">{`${formatPriceFromCents(
                      incentiveTotal,
                      false
                    )} from rate boosts `}</div>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <PageSection className="m-0 p-0">
        {pageSlice && (
          <Table aria-labelledby="upcoming-payments-title">
            <TableHeader>
              <Column>Payment date</Column>
              <Column>Description</Column>
              <Column align="right">
                Estimated amount
                <span className="mr-5" />
              </Column>
            </TableHeader>
            <TableBody>
              {pageSlice.map((e, idx) => {
                return (
                  <Row key={idx}>
                    <Cell>
                      {e &&
                        (() => {
                          let baseDate = moment(e.paymentLandingDate);

                          if (
                            isPausedPaymentsFlagEnabled &&
                            e.providerPaymentStatus ===
                              ProviderPaymentStatus.UNDER_REVIEW
                          ) {
                            return 'Under review';
                          }
                          return baseDate.format('M/D/YYYY');
                        })()}
                    </Cell>
                    <Cell>
                      {e && e.appointmentInfo ? (
                        <FormattedAppointmentInfo
                          status={e.appointmentInfo.status}
                          billingType={e.appointmentInfo.billingType}
                          appointmentStartAt={
                            e.appointmentInfo.appointmentStartAt
                          }
                          patientName={e.appointmentInfo.patientName}
                          patientUserId={e.appointmentInfo.patientUserId}
                          frontEndCarrier={e.appointmentInfo.frontEndCarrier}
                          appointmentStateLocation={
                            e.appointmentInfo.appointmentStateLocation
                          }
                          isTelehealth={e.appointmentInfo.isTelehealth}
                          isProviderIncentiveBonus={e.isProviderIncentiveBonus}
                        />
                      ) : (
                        'Unable to format appointment details'
                      )}
                    </Cell>
                    <Cell>
                      {e && e.appointmentInfo && (
                        <div
                          className={clsx(
                            'ml-auto',
                            e.isProviderIncentiveBonus && 'text-primary-blue'
                          )}
                        >
                          {formatPriceFromCents(e.amountCents, false)}
                          <PaymentFeesTooltip
                            fees={e.appointmentInfo.providerPaymentFeesAmount}
                            billingType={e.appointmentInfo.billingType}
                          />
                        </div>
                      )}
                    </Cell>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        )}
      </PageSection>
      {!error && hasUpcomingPayments ? (
        <TablePagination
          component="div"
          css={{
            '& p': {
              margin: 0,
            },
          }}
          rowsPerPageOptions={[25, 50, 100]}
          count={upcomingPayments.length ?? 0}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : null}
      {!isLoading && !error && !hasUpcomingPayments ? (
        <div className="mx-auto mb-10 mt-10 max-w-[30rem] text-center">
          No upcoming payments to display. Confirm session details to see
          upcoming payments.
        </div>
      ) : null}
      {error ? (
        <div className="mx-auto mb-12 mt-12 max-w-6xl">
          <GuidanceCard variant="error">
            We can't load your upcoming payments. Refresh the page to see if
            this is an ongoing issue.
          </GuidanceCard>
        </div>
      ) : null}
    </div>
  );
}
