import { Formik } from 'formik';
import { useProvider } from 'hooks';
import { flatMap, isEqual } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Yup from 'yup';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import {
  AddOrUpdateTreatmentPlanButtonClickedEvent,
  CompleteAndSignDraftButtonClickedEvent,
  CreateTreatmentPlanOrUploadExistingButtonClickedEvent,
  ShowTreatmentPlanButtonClickedEvent,
} from '@headway/avo';
import { Badge } from '@headway/helix/Badge';
import { Banner } from '@headway/helix/Banner';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { Item, Select } from '@headway/helix/Select';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { lateEntryReasonDisplayNames } from '@headway/shared/constants/lateEntryReasonDisplayNames';
import {
  PROGRESS_NOTES_LATE_ENTRY,
  PROGRESS_NOTES_SIGNATURE_RESTRICTIONS,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { useQuery } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { ChangeListener } from '@headway/ui/form/ChangeListener';
import { toaster } from '@headway/ui/ToastManager';

import { BigRadio } from 'components/BigRadio/BigRadio';
import { BigRadioGroup } from 'components/BigRadio/BigRadioGroup';
import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';
import { useMedicareOrMedicaid } from 'hooks/useMedicareOrMedicaid';
import { useProviderEvent } from 'hooks/useProviderEvent';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';
import { isDetailsConfirmed } from 'views/Calendar/events/util/events';
import RateBoostGuidanceCard from 'views/Incentives/RateBoostGuidanceCard';

import { AppointmentContext } from '../../stores/AppointmentContext';
import {
  createNoteJsonObject,
  ProgressNoteContext,
  ProgressNotePermissions,
  ProgressNoteState,
} from '../../stores/ProgressNotesContext';
import { useProgressNoteRequirements } from './components/requirements/ProgressNoteRequirements';
import { ProgressNotesSubmitListener } from './ProgressNotesSubmitListener';
import { createYupSchema, TemplateErrorConfig } from './templateView/errors';
import {
  GenericTemplate,
  NoteJson,
  TemplateValues,
} from './templateView/schemaRenderer/versions/types';
import {
  getInitialTemplateValues,
  Template,
} from './templateView/templateView';
import {
  alphabeticallySortTemplates,
  applyTemplateRolloutFilter,
  createComponentErrorMap,
  getInitialErrors,
  getTemplates,
} from './templateView/utils';
import { ProgressNoteComponentMetadata } from './types';
import { getRecommendedTemplateForCPTCode } from './utils';

interface ProgressNotesFormProps {
  eventVirtualId: string;
  patient: UserRead;
  innerRef: React.RefObject<any>;
  updateSavingState: (changeTo: boolean) => void;
  resetAttestation: () => void;
  providerPatient: ProviderPatientRead;
  setIsSelectedTemplateFreeText: (changeTo: boolean) => void;
  setIsSelectedNoteUpload: (changeTo: boolean) => void;
}

export type TemplateAnalyticsTypes =
  | AddOrUpdateTreatmentPlanButtonClickedEvent
  | CompleteAndSignDraftButtonClickedEvent
  | CreateTreatmentPlanOrUploadExistingButtonClickedEvent
  | ShowTreatmentPlanButtonClickedEvent;
export type TemplateAnalyticsNames =
  | AddOrUpdateTreatmentPlanButtonClickedEvent['name']
  | CompleteAndSignDraftButtonClickedEvent['name']
  | CreateTreatmentPlanOrUploadExistingButtonClickedEvent['name']
  | ShowTreatmentPlanButtonClickedEvent['name'];

const createTemplateIdAndVersionFormValue = (id: number, version: number) =>
  `${id}-${version}`;

export const getTemplateIdAndVersionFromFormValue = (formValue?: string) => {
  if (!formValue?.includes('-')) {
    return [undefined, undefined];
  }

  try {
    const [id, version] = formValue.split('-');
    return [parseInt(id), parseInt(version)];
  } catch {
    throw new Error('Failed getting template id and version from form value');
  }
};

const templates = getTemplates<ProgressNoteComponentMetadata>();
export const ProgressNotesForm = ({
  innerRef,
  eventVirtualId,
  patient,
  updateSavingState,
  resetAttestation,
  providerPatient,
  setIsSelectedTemplateFreeText,
  setIsSelectedNoteUpload,
}: ProgressNotesFormProps) => {
  const { user } = useAuthStore();
  const provider = useProvider();
  const isGroupPracticeAdmin = isGroupAdminImpersonatingProvider(
    provider,
    user
  );

  const {
    progressNote,
    isProgressNoteLoading,
    previousProgressNotes,
    getPreviousProgressNotes,
    notePermissions,
    progressNoteState,
    updateNote,
    selectedTemplate,
    setSelectedTemplate,
    progressNoteType,
    setProgressNoteType,
    setMetadataInfo,
    progressNoteErrors,
    setProgressNoteErrors,
  } = useContext(ProgressNoteContext);

  const [initialValues, setInitialValues] = useState<any>({
    progressNoteType,
    template: null,
    previousNote: null,
    lateEntryReason: null,
    lateEntryOtherReason: null,
  });

  const [initialTemplateValues, setInitialTemplateValues] = useState({});
  const [templateHasChanges, setTemplateHasChanges] = useState(false);
  const [nextTemplateType, setNextTemplateType] = useState<
    string | undefined
  >();
  const [hasPastedFreeTextNote, setHasPastedFreeTextNote] = useState(false);
  const [showCopyPasteBanner, setShowCopyPasteBanner] = useState(false);

  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });
  const progressNotesTemplatesFF = useFlag('progressNoteTemplates', false);
  const signatureRestrictionsEnabled = useFlag(
    PROGRESS_NOTES_SIGNATURE_RESTRICTIONS,
    false
  );

  const progressNotesLateEntryEnabled = useFlag(
    PROGRESS_NOTES_LATE_ENTRY,
    false
  );

  const { selectedCptCodes } = useContext(AppointmentContext);

  const [templateInputHappened, setTemplateInputHappened] = useState(false);
  const [canRecommendTemplate, setCanRecommendTemplate] = useState(true);

  const { allowedProgressNoteTypes, ...progressNoteRequirements } =
    useProgressNoteRequirements({
      patient,
      event,
    });

  // Decide if we should hide note types
  const shouldShowProgressNoteType = (type: ProgressNoteType) => {
    if (!progressNoteRequirements.hiddenProgressNoteTypes) return true;

    return !progressNoteRequirements.hiddenProgressNoteTypes.includes(type);
  };

  // Decide if we should disable note types
  const shouldAllowProgressNoteType = useCallback(
    (type: ProgressNoteType) => {
      if (!allowedProgressNoteTypes) return true;

      return allowedProgressNoteTypes.includes(type);
    },
    [allowedProgressNoteTypes]
  );

  const shouldAllowTemplatedNote = useMemo(() => {
    const isNoteSignedElsewhere =
      isDetailsConfirmed(event) &&
      event?.providerAppointment?.progressNoteType === ProgressNoteType.NONE;

    if (signatureRestrictionsEnabled && isNoteSignedElsewhere) {
      return false;
    }

    return shouldAllowProgressNoteType(ProgressNoteType.TEMPLATE);
  }, [shouldAllowProgressNoteType, event, signatureRestrictionsEnabled]);

  const [
    providerClosedConsiderHeadwayTemplateCard,
    setProviderClosedConsiderHeadwayTemplateCard,
  ] = useLocalStorage('providerClosedConsiderHeadwayTemplateCard');

  const { data: providerEnrollmentSummary } =
    useProviderIncentiveProgramEnrollment({
      providerId: provider.id,
    });

  useEffect(() => {
    if (progressNoteErrors?.length > 0) {
      let first = progressNoteErrors[0];
      const element = document.querySelector(`[name="${first.component_id}"]`);
      element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [progressNoteErrors]);

  const getTemplate = useCallback(
    (id?: number, version?: number) =>
      id !== undefined && version !== undefined && templates !== undefined
        ? templates?.find((t) => {
            return (
              t.templateInfo.id === id && t.templateInfo.version === version
            );
          })
        : undefined,
    []
  );

  const prefillFormValues = (
    template: GenericTemplate<ProgressNoteComponentMetadata>,
    values: TemplateValues
  ) => {
    setInitialTemplateValues(getInitialTemplateValues(template, values));
  };

  const [selectedPreviousNote, setSelectedPreviousNote] = useState<
    number | undefined
  >();

  // if user has not touched the progress note/template selection but has been changing cpt code,
  // allow CPT recommendation
  useEffect(() => {
    setCanRecommendTemplate(true);
  }, [selectedCptCodes]);

  // determines CPT recommendation template
  useEffect(() => {
    if (
      isProgressNoteLoading ||
      !!progressNote?.attestedOn ||
      !canRecommendTemplate
    ) {
      return;
    }

    if (
      (selectedTemplate &&
        progressNote?.noteJson &&
        Object.keys((progressNote.noteJson as NoteJson).response || {})
          .length !== 0) ||
      progressNoteType === ProgressNoteType.UPLOAD ||
      progressNoteType === ProgressNoteType.NONE
    ) {
      if (progressNote?.noteJson && selectedTemplate) {
        if (
          !isEqual(
            (progressNote?.noteJson as NoteJson).response,
            getInitialTemplateValues(
              getTemplate(
                selectedTemplate.id,
                selectedTemplate.version
              ) as GenericTemplate<unknown>
            )
          )
        ) {
          return;
        }

        if (templateInputHappened) {
          return;
        }
      } else {
        return;
      }
    }

    const recommendedTemplate = getRecommendedTemplateForCPTCode(
      progressNotesTemplatesFF,
      selectedCptCodes
    );

    if (!recommendedTemplate) {
      return;
    }

    const template = getTemplate(
      recommendedTemplate?.id,
      recommendedTemplate?.version
    );

    if (!template) {
      return;
    }

    setSelectedTemplate(template.templateInfo);
    setTemplate(
      createTemplateIdAndVersionFormValue(
        template.templateInfo.id,
        template.templateInfo.version
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progressNote,
    selectedCptCodes,
    isProgressNoteLoading,
    getTemplate,
    progressNoteType,
    progressNotesTemplatesFF,
    selectedTemplate,
    setSelectedTemplate,
    templateInputHappened,
  ]);

  // If we have a progressNote, initialize our state
  useEffect(() => {
    if (!progressNote || isEqual(progressNote.noteJson, {})) {
      return;
    }

    const noteJson = progressNote?.noteJson as NoteJson;
    if (noteJson) {
      const template = getTemplate(
        noteJson.templateInfo.id,
        noteJson.templateInfo.version
      );
      if (template) {
        setSelectedTemplate(template.templateInfo);
        isCurrentlyFreeTextTemplate(template);
        getPreviousProgressNotes(
          template.templateInfo.id,
          patient.id,
          provider.id
        );
        prefillFormValues(template, noteJson.response);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressNote?.id, getTemplate]);

  // Set form values if current progress note exists
  useEffect(() => {
    setInitialValues({
      progressNoteType,
      template: selectedTemplate
        ? createTemplateIdAndVersionFormValue(
            selectedTemplate.id,
            selectedTemplate.version
          )
        : null,
      previousNote: selectedPreviousNote ?? null,
      lateEntryReason: progressNote?.lateEntryReason ?? null,
      lateEntryOtherReason: progressNote?.lateEntryOtherReason ?? null,
      ...initialTemplateValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedTemplate,
    selectedPreviousNote,
    progressNoteType,
    // eslint-disable-next-line
    JSON.stringify(initialTemplateValues),
    shouldAllowTemplatedNote,
  ]);

  /**
   * Set initial Progress Note Type if requirements specificy allowed types
   */
  useEffect(() => {
    if (
      !isProgressNoteLoading &&
      allowedProgressNoteTypes &&
      allowedProgressNoteTypes.length > 0 &&
      !allowedProgressNoteTypes.includes(progressNoteType) &&
      !isDetailsConfirmed(event)
    ) {
      setProgressNoteType(allowedProgressNoteTypes[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isProgressNoteLoading,
    allowedProgressNoteTypes,
    setProgressNoteType,
    event?.providerAppointment?.status,
  ]);

  // if user pastes into free text template box, show pasted banner.
  // If user switches to another progress note type and goes back to free text, remove banner
  useEffect(() => {
    const handlePaste = (event: any) => {
      setHasPastedFreeTextNote(true);
    };

    if (selectedTemplate) {
      if (selectedTemplate.name.toLowerCase().includes('free')) {
        document.addEventListener('paste', handlePaste);
      }
    }

    if (showCopyPasteBanner && progressNoteType !== ProgressNoteType.TEMPLATE) {
      setShowCopyPasteBanner(false);
      setHasPastedFreeTextNote(false);
    }

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [
    selectedTemplate,
    hasPastedFreeTextNote,
    progressNoteType,
    showCopyPasteBanner,
  ]);

  const isCurrentlyFreeTextTemplate = (
    template: GenericTemplate<ProgressNoteComponentMetadata>
  ) => {
    const isFreeTextTemplate = template.templateInfo.name
      .toLowerCase()
      .includes('free');

    return isFreeTextTemplate
      ? setIsSelectedTemplateFreeText(true)
      : setIsSelectedTemplateFreeText(false);
  };

  const isCurrentlyProgressNoteUpload = (value: string) => {
    return value === ProgressNoteType.UPLOAD
      ? setIsSelectedNoteUpload(true)
      : setIsSelectedNoteUpload(false);
  };

  const { isLoading, data: providerEventsData } = useQuery(
    ['getPreviousAppointmentSession'],
    async () => {
      return Promise.all(
        (previousProgressNotes || []).map((prevNote) => {
          return ProviderEventApi.getEvents({
            provider_id: prevNote.providerId,
            patient_user_id: prevNote.patientId,
            provider_appointment_ids: [prevNote?.providerAppointmentId],
            expand_estimated_prices: false,
          });
        })
      );
    },
    {
      enabled: !!previousProgressNotes?.length,
    }
  );

  const setTemplate = (
    value: string | undefined,
    setFieldValue?: (field: string, value: any) => void
  ) => {
    if (setFieldValue) {
      setFieldValue('template', value);
      setFieldValue('previousNote', null);
    }

    if (!value) {
      return;
    }

    const template = getTemplate(
      ...getTemplateIdAndVersionFromFormValue(value)
    );

    if (!template) {
      return;
    }

    getPreviousProgressNotes(
      template?.templateInfo.id,
      patient.id,
      provider.id
    );

    setSelectedTemplate(template.templateInfo);
    isCurrentlyFreeTextTemplate(template);
    setSelectedPreviousNote(undefined);
    setProgressNoteErrors([]);
    setMetadataInfo({
      noteJsonPrefilledFrom: undefined,
    });

    setInitialTemplateValues(getInitialTemplateValues(template));
  };

  const sendTemplateAnalyticsEvent = (
    trackingEventName: TemplateAnalyticsNames
  ) => {
    if (!progressNote?.providerAppointmentId || !progressNote?.id) {
      return;
    }

    trackEvent({
      name: trackingEventName,
      properties: {
        providerId: progressNote?.providerId,
        providerAppointmentId: progressNote?.providerAppointmentId,
        progressNoteId: progressNote?.id,
      },
    });
  };

  const sendHeadwayTemplateAnalyticsEvent = (
    progressNoteType: ProgressNoteType
  ) => {
    if (provider?.id && event?.providerAppointment?.id && progressNoteType) {
      trackEvent({
        name: 'Use Headway Template Modal Click',
        properties: {
          providerId: provider?.id,
          providerAppointmentId: event?.providerAppointment.id,
          progressNoteRadio: progressNoteType,
        },
      });
    }
  };

  const hoursSinceEndofAppointment = event?.endDate
    ? Math.floor(
        (new Date().valueOf() - new Date(event.endDate).valueOf()) /
          (1000 * 3600)
      )
    : 0;

  const isMedicareOrMedicaid = useMedicareOrMedicaid(patient?.id);
  const showLateEntrySection =
    progressNotesLateEntryEnabled &&
    ((isMedicareOrMedicaid && hoursSinceEndofAppointment >= 48) ||
      (!isMedicareOrMedicaid && hoursSinceEndofAppointment >= 72));

  // Only show the show rate boost banner + badges if every other banners or
  // requirements are not shown and if the appointment has not been confirmed
  // yet (more priority to other banners and flows)
  const showRateBoostInfo =
    providerEnrollmentSummary?.isProviderEnrolled &&
    !showLateEntrySection &&
    !isDetailsConfirmed(event) &&
    !progressNoteRequirements.banner &&
    !allowedProgressNoteTypes;

  let filteredTemplateList = alphabeticallySortTemplates(
    applyTemplateRolloutFilter(
      templates,
      progressNotesTemplatesFF,
      selectedTemplate
        ? {
            id: selectedTemplate.id,
            version: selectedTemplate.version,
          }
        : undefined
    )
  );
  if (progressNoteRequirements.templateFilter) {
    filteredTemplateList =
      progressNoteRequirements.templateFilter(filteredTemplateList);
  }

  const validationSchema = useMemo(() => {
    if (progressNoteType === ProgressNoteType.TEMPLATE && selectedTemplate) {
      const lateEntrySchema = showLateEntrySection
        ? {
            lateEntryReason: Yup.string()
              .nullable()
              .required('Please select a reason for late entry'),
            lateEntryOtherReason: Yup.string()
              .nullable()
              .when('lateEntryReason', {
                is: ProviderProgressNoteLateEntryReason.OTHER,
                then: Yup.string()
                  .nullable()
                  .required('Please provide a reason'),
              }),
          }
        : undefined;

      let progressNotesSchema;
      if (progressNoteErrors) {
        const template = getTemplate(
          selectedTemplate?.id,
          selectedTemplate?.version
        );
        const componentErrorMap = createComponentErrorMap(
          progressNoteErrors.length === 0
            ? getInitialErrors(template, selectedCptCodes)
            : progressNoteErrors,
          template!
        );
        const componentErrorMapAsArray = flatMap(
          componentErrorMap,
          (val: TemplateErrorConfig, key) => ({
            id: key,
            type: val?.type,
            validationType: val?.validationType,
            validations: val?.validations,
          })
        );

        const schema = componentErrorMapAsArray.reduce(createYupSchema, {});
        progressNotesSchema = schema;
      }

      return Yup.object().shape({
        ...progressNotesSchema,
        ...lateEntrySchema,
      });
    } else {
      return null;
    }
  }, [
    progressNoteErrors,
    selectedTemplate,
    getTemplate,
    showLateEntrySection,
    progressNoteType,
    selectedCptCodes,
  ]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (progressNoteState !== ProgressNoteState.EDITING) {
          return;
        }

        const {
          template,
          progressNoteType,
          previousNote,
          lateEntryReason,
          lateEntryOtherReason,
          ...response
        } = values;

        if (!template) {
          return;
        }

        const selectedTemplate = getTemplate(
          ...getTemplateIdAndVersionFromFormValue(template)
        );

        if (!selectedTemplate) {
          return;
        }

        const noteJson = createNoteJsonObject(selectedTemplate, response);

        if (progressNote) {
          try {
            updateSavingState(true);
            await updateNote({
              noteJson,
              lateEntryReason,
              lateEntryOtherReason,
            });
            updateSavingState(false);
          } catch (error) {
            toaster.error(
              `We were unable to update the progress note: ${error}`
            );
          }
        }
      }}
      innerRef={innerRef as any}
    >
      {({ values, setFieldValue, resetForm }) => {
        // show pasted banner for free text box
        if (hasPastedFreeTextNote) {
          if (values.template) {
            const template = getTemplate(
              ...getTemplateIdAndVersionFromFormValue(values.template)
            );

            if (template?.templateInfo.name.toLowerCase().includes('free')) {
              const component = (template.template as any).find(
                (component: any) => component.type === 'richFreeText'
              );
              if (values[component.id] !== initialValues[component.id]) {
                setShowCopyPasteBanner(true);
                setHasPastedFreeTextNote(false);
              }
            }
          }
        }

        const isNoteSavedElsewhereTemplate =
          values.progressNoteType === ProgressNoteType.NONE;

        const isUploadTemplate =
          values.progressNoteType === ProgressNoteType.UPLOAD;

        const isIncompleteUploadOrNoteTemplate =
          isUploadTemplate || isNoteSavedElsewhereTemplate;

        const isIncompleteFreeTextTemplate =
          values.template &&
          getTemplate(...getTemplateIdAndVersionFromFormValue(values.template))
            ?.templateInfo.name.toLowerCase()
            .includes('free') &&
          progressNoteState !== ProgressNoteState.SIGNED &&
          progressNoteState !== ProgressNoteState.SAVED_FOR_LATER &&
          progressNoteState !== ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT;

        /**
         * Determines if any template input has happened by comparing
         * the response to the initial value
         */
        if (selectedTemplate && !templateInputHappened) {
          const { template, progressNoteType, previousNote, ...response } =
            values;

          if (template) {
            const fullTemplate = getTemplate(
              ...getTemplateIdAndVersionFromFormValue(template)
            );
            if (fullTemplate) {
              const noteJson = createNoteJsonObject(
                fullTemplate as GenericTemplate<unknown>,
                response
              );
              if (
                !isEqual(noteJson.response, {}) &&
                !isEqual(
                  noteJson.response,
                  getInitialTemplateValues(fullTemplate)
                )
              ) {
                setTemplateInputHappened(true);
              }
            }
          }
        }

        const sessionConfirmationDate =
          event?.providerAppointment?.sessionDetailsConfirmedOn &&
          new Date(
            event.providerAppointment.sessionDetailsConfirmedOn
          ).toLocaleDateString([], {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });

        const showNoteSavedElsewhereSessionConfirmation =
          isDetailsConfirmed(event) &&
          event?.providerAppointment?.progressNoteType ===
            ProgressNoteType.NONE;

        const showProgressNoteBanners =
          !isProgressNoteLoading &&
          progressNoteState === ProgressNoteState.EDITING &&
          (showRateBoostInfo || progressNoteRequirements.banner);

        return (
          <div css={{ marginBottom: theme.spacing.x6 }}>
            {progressNoteState === ProgressNoteState.EDITING && (
              <>
                {templateInputHappened && (
                  <ProgressNotesSubmitListener
                    considerInitialValues={false}
                    updateSavingState={updateSavingState}
                    providerId={provider.id}
                    patientId={patient.id}
                    providerAppointmentId={event?.providerAppointment?.id}
                  />
                )}
                <ChangeListener
                  onChange={(hasChanges: boolean) => {
                    setTemplateHasChanges(hasChanges);
                  }}
                  ignoreFields={[
                    'previousNote',
                    'template',
                    'progressNoteType',
                  ]}
                />
              </>
            )}

            {showProgressNoteBanners && (
              <div className="mb-5">
                {progressNoteRequirements.banner ? (
                  progressNoteRequirements.banner
                ) : showRateBoostInfo ? (
                  <RateBoostGuidanceCard />
                ) : null}
              </div>
            )}

            <Form name="progress-notes-form">
              {!isProgressNoteLoading &&
                progressNoteState === ProgressNoteState.EDITING &&
                !progressNoteRequirements.preventSwitching && (
                  <FormControl
                    name="progressNoteType"
                    component={BigRadioGroup}
                    onChange={(value: string) => {
                      setProgressNoteType(
                        ProgressNoteType[value as keyof typeof ProgressNoteType]
                      );
                      isCurrentlyProgressNoteUpload(value);
                      resetAttestation();
                    }}
                    aria-label="Fill out a template or upload an existing note"
                    disabled={
                      progressNoteState !== ProgressNoteState.EDITING ||
                      isProgressNoteLoading
                    }
                  >
                    {shouldShowProgressNoteType(ProgressNoteType.TEMPLATE) && (
                      <BigRadio
                        key="template"
                        value={ProgressNoteType.TEMPLATE}
                        disabled={!shouldAllowTemplatedNote}
                      >
                        <div>
                          <strong>Fill out template</strong>
                          <p>Create a structured, compliant note</p>
                          {showRateBoostInfo && (
                            <Badge variant="info">Rate boost qualified</Badge>
                          )}
                        </div>
                      </BigRadio>
                    )}
                    {shouldShowProgressNoteType(ProgressNoteType.UPLOAD) && (
                      <BigRadio
                        key="upload"
                        value={ProgressNoteType.UPLOAD}
                        disabled={
                          !shouldAllowProgressNoteType(ProgressNoteType.UPLOAD)
                        }
                      >
                        <div>
                          <strong>Upload existing note</strong>
                          <p>Use your own format or a note signed elsewhere</p>
                          {showRateBoostInfo && (
                            <Badge variant="info">Rate boost qualified</Badge>
                          )}
                        </div>
                      </BigRadio>
                    )}
                    {shouldShowProgressNoteType(ProgressNoteType.NONE) && (
                      <BigRadio
                        key="none"
                        value={ProgressNoteType.NONE}
                        disabled={
                          !shouldAllowProgressNoteType(ProgressNoteType.NONE)
                        }
                      >
                        <div>
                          <strong>My note is saved elsewhere</strong>
                          <p>Confirm your note exists outside of Headway</p>
                        </div>
                      </BigRadio>
                    )}
                  </FormControl>
                )}
              {isIncompleteUploadOrNoteTemplate &&
                !providerClosedConsiderHeadwayTemplateCard && (
                  <CreateHeadwayTemplateGuidanceCard
                    setProviderClosedConsiderHeadwayTemplateCard={
                      setProviderClosedConsiderHeadwayTemplateCard
                    }
                    provider={provider}
                    sendHeadwayTemplateAnalyticsEvent={
                      sendHeadwayTemplateAnalyticsEvent
                    }
                    progressNoteType={progressNoteType}
                  />
                )}
              {(isUploadTemplate ||
                (isNoteSavedElsewhereTemplate &&
                  !signatureRestrictionsEnabled)) &&
                providerClosedConsiderHeadwayTemplateCard &&
                !(progressNoteRequirements.banner && isGroupPracticeAdmin) && (
                  <SuggestionGuidanceCard isUploadOrNote={true} />
                )}
              {signatureRestrictionsEnabled &&
                providerClosedConsiderHeadwayTemplateCard &&
                !showNoteSavedElsewhereSessionConfirmation &&
                progressNoteState === ProgressNoteState.EDITING &&
                isNoteSavedElsewhereTemplate && (
                  <GuidanceCard
                    variant="compliance"
                    title="Important compliance requirements when saving your notes elsewhere"
                  >
                    <BodyText>
                      By choosing <strong>My note is saved elsewhere</strong>,
                      you acknowledge two things:
                    </BodyText>
                    <ol className="m-0 pl-4">
                      <li>
                        <BodyText>
                          You have a copy of the note you can provide at a later
                          date if requested.
                        </BodyText>
                      </li>
                      <li>
                        <BodyText>
                          You will no longer be able to fill out a note template
                          later (this ensures only one signed copy of the note
                          exists, which is important for compliance).
                        </BodyText>
                      </li>
                    </ol>
                  </GuidanceCard>
                )}
              {signatureRestrictionsEnabled &&
                showNoteSavedElsewhereSessionConfirmation &&
                progressNoteState === ProgressNoteState.EDITING &&
                isNoteSavedElsewhereTemplate && (
                  <div>
                    <BodyText>
                      {sessionConfirmationDate ? (
                        <>
                          On <strong>{sessionConfirmationDate},</strong> you{' '}
                        </>
                      ) : (
                        'You '
                      )}
                      confirmed this session and indicated your note was saved
                      elsewhere. You may upload that document by selecting{' '}
                      <strong>Upload existing note</strong> above.
                    </BodyText>
                  </div>
                )}
              {progressNoteType === ProgressNoteType.TEMPLATE &&
                templates !== undefined &&
                templates.length > 0 && (
                  <>
                    <div
                      css={{
                        ...theme.stack.horizontal,
                        gap: theme.spacing.x2,
                        [theme.__futureMedia.phone]: {
                          flexDirection: 'column',
                        },
                      }}
                    >
                      <div
                        css={{
                          flexGrow: 1,
                          [theme.__futureMedia.phone]: { width: '100%' },
                        }}
                      >
                        <Select
                          name="template"
                          placeholder="Choose template"
                          label="Template"
                          selectionMode="single"
                          menuWidth="stretch"
                          helpText={
                            values.template &&
                            getTemplateIdAndVersionFromFormValue(
                              values.template
                            )[0] ===
                              getRecommendedTemplateForCPTCode(
                                progressNotesTemplatesFF,
                                selectedCptCodes
                              )?.id
                              ? 'This is the best template for the CPT code you selected'
                              : ''
                          }
                          selectedKeys={
                            values.template ? [values.template] : []
                          }
                          disabled={
                            progressNoteState !== ProgressNoteState.EDITING ||
                            !notePermissions.includes(
                              ProgressNotePermissions.WRITE
                            ) ||
                            !notePermissions.includes(
                              ProgressNotePermissions.READ
                            ) ||
                            isProgressNoteLoading
                          }
                          onSelectionChange={(keys) => {
                            const [value] = keys;
                            setCanRecommendTemplate(false);
                            if (templateHasChanges) {
                              setNextTemplateType(value);
                            } else {
                              setTemplate(value, setFieldValue);
                            }
                          }}
                          items={filteredTemplateList.map((template) => ({
                            key: `${template.templateInfo.id}-${template.templateInfo.version}`,
                            name: template.templateInfo.name,
                          }))}
                        >
                          {(template) => (
                            <Item textValue={template.name}>
                              <div
                                css={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: theme.spacing.x2,
                                }}
                              >
                                {template.name}{' '}
                                {getTemplateIdAndVersionFromFormValue(
                                  template.key
                                )[0] ===
                                getRecommendedTemplateForCPTCode(
                                  progressNotesTemplatesFF,
                                  selectedCptCodes
                                )?.id ? (
                                  <Badge variant="info">Recommended</Badge>
                                ) : null}
                              </div>
                            </Item>
                          )}
                        </Select>
                      </div>
                      <div
                        css={{
                          flexGrow: 0,
                          [theme.__futureMedia.phone]: { width: '100%' },
                        }}
                      >
                        {!isLoading && (
                          <Select
                            name="previousNote"
                            label="Previous Session"
                            placeholder="Prefill from past session"
                            selectionMode="single"
                            menuWidth="stretch"
                            disabled={
                              progressNoteState !== ProgressNoteState.EDITING ||
                              !previousProgressNotes ||
                              previousProgressNotes.length <= 0
                            }
                            selectedKeys={
                              values.previousNote ? [values.previousNote] : []
                            }
                            onSelectionChange={(keys) => {
                              const [value] = keys;

                              setFieldValue('previousNote', value);
                              setSelectedPreviousNote(parseInt(value));
                              setMetadataInfo({
                                noteJsonPrefilledFrom: parseInt(value),
                              });

                              const previousValues = (
                                previousProgressNotes?.find(
                                  (note) =>
                                    note.id.toString() === value?.toString()
                                )?.noteJson as NoteJson
                              )?.response as TemplateValues;

                              const template = values?.template
                                ? getTemplate(
                                    ...getTemplateIdAndVersionFromFormValue(
                                      values.template
                                    )
                                  )
                                : undefined;

                              // Prefill form with previous values
                              if (previousValues) {
                                if (template) {
                                  const newValues = getInitialTemplateValues(
                                    template,
                                    values
                                  );
                                  resetForm({
                                    values: {
                                      ...initialValues,
                                      ...newValues,
                                    },
                                  });
                                  prefillFormValues(template, previousValues);
                                }
                              } else {
                                // Reset form and clear values
                                if (template) {
                                  const resetValues =
                                    getInitialTemplateValues(template);
                                  resetForm({
                                    values: {
                                      ...resetValues,
                                    },
                                  });
                                  prefillFormValues(template, resetValues);
                                }
                              }
                            }}
                            items={previousProgressNotes ?? []}
                          >
                            {(note) => {
                              const previousAppt = providerEventsData?.find(
                                (prevEvent) => {
                                  return (
                                    prevEvent?.data[0]?.providerAppointment
                                      ?.id === note.providerAppointmentId
                                  );
                                }
                              );

                              const date = new Date(
                                previousAppt?.data[0]?.startDate ||
                                  note.lastSavedOn
                              );
                              return (
                                <Item>
                                  {`${date.toLocaleDateString([], {
                                    year: '2-digit',
                                    month: 'numeric',
                                    day: 'numeric',
                                  })} at ${date.toLocaleTimeString([], {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  })}`}
                                </Item>
                              );
                            }}
                          </Select>
                        )}
                      </div>
                    </div>
                    {showLateEntrySection && !!values.template && (
                      <>
                        {progressNoteState === ProgressNoteState.EDITING && (
                          <Banner variant="warning">
                            {isMedicareOrMedicaid
                              ? `Medicare and Medicaid notes must be signed within 48 hours`
                              : `Notes must be signed within 72 hours`}{' '}
                            of each session to meet compliance standards. Over
                            time, late notes can lead to payment delays or
                            additional claim reviews, though it’s better to sign
                            late than not sign a note at all.{' '}
                            <Link
                              href="https://help.headway.co/hc/en-us/articles/25175689760532-Compliance-deadlines-for-clinical-documentation-and-why-it-matters"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Learn more about note deadlines.
                            </Link>
                            <p css={{ marginTop: theme.spacing.x6 }}>
                              Already signed your note somewhere else? Switch to
                              “My note is saved elsewhere” above.
                            </p>
                          </Banner>
                        )}
                        <FormControl
                          component={Select}
                          name="lateEntryReason"
                          label="Reason for late submission"
                          selectionMode="single"
                          menuWidth="stretch"
                          disabled={
                            (progressNoteState !== ProgressNoteState.EDITING &&
                              progressNoteState !==
                                ProgressNoteState.ADDENDUM_EDITING) ||
                            !notePermissions.includes(
                              ProgressNotePermissions.WRITE
                            )
                          }
                        >
                          {Object.keys(ProviderProgressNoteLateEntryReason).map(
                            (reason) => (
                              <Item key={reason}>
                                {
                                  lateEntryReasonDisplayNames[
                                    reason as ProviderProgressNoteLateEntryReason
                                  ]
                                }
                              </Item>
                            )
                          )}
                        </FormControl>
                        {values.lateEntryReason ===
                          ProviderProgressNoteLateEntryReason.OTHER && (
                          <FormControl
                            component={TextField}
                            name="lateEntryOtherReason"
                            label="Describe your reason for late submission"
                            disabled={
                              (progressNoteState !==
                                ProgressNoteState.EDITING &&
                                progressNoteState !==
                                  ProgressNoteState.ADDENDUM_EDITING) ||
                              !notePermissions.includes(
                                ProgressNotePermissions.WRITE
                              )
                            }
                            onChange={(value) => {
                              setFieldValue('lateEntryOtherReason', value);
                            }}
                            helpText={
                              'This will be included in your signed note'
                            }
                          />
                        )}
                      </>
                    )}
                    {isIncompleteFreeTextTemplate &&
                      !providerClosedConsiderHeadwayTemplateCard && (
                        <CreateHeadwayTemplateGuidanceCard
                          setProviderClosedConsiderHeadwayTemplateCard={
                            setProviderClosedConsiderHeadwayTemplateCard
                          }
                          provider={provider}
                          sendHeadwayTemplateAnalyticsEvent={
                            sendHeadwayTemplateAnalyticsEvent
                          }
                          progressNoteType={progressNoteType}
                        />
                      )}
                    {isIncompleteFreeTextTemplate &&
                      providerClosedConsiderHeadwayTemplateCard && (
                        <SuggestionGuidanceCard isUploadOrNote={false} />
                      )}
                    {showCopyPasteBanner && isIncompleteFreeTextTemplate && (
                      <GuidanceCard variant={'warning'}>
                        You pasted your note. If you already signed this note
                        elsewhere, please upload the original note instead. Your
                        original signature and timestamp are important for
                        meeting insurance compliance standards.
                      </GuidanceCard>
                    )}
                    {!notePermissions.includes(ProgressNotePermissions.READ) ? (
                      <SubBodyText>
                        To protect client privacy, you do not have permission to
                        read this progress note
                      </SubBodyText>
                    ) : !notePermissions.includes(
                        ProgressNotePermissions.WRITE
                      ) ? (
                      <SubBodyText>
                        Only the provider present at the session can write a
                        progress note
                      </SubBodyText>
                    ) : null}
                    {isIncompleteFreeTextTemplate &&
                    !providerClosedConsiderHeadwayTemplateCard ? null : values.template &&
                      getTemplate(
                        ...getTemplateIdAndVersionFromFormValue(values.template)
                      ) ? (
                      <Template<ProgressNoteComponentMetadata>
                        template={
                          getTemplate(
                            ...getTemplateIdAndVersionFromFormValue(
                              values.template
                            )
                          )!
                        }
                        event={event}
                        patient={patient}
                        providerPatient={providerPatient}
                        sendTemplateAnalytics={sendTemplateAnalyticsEvent}
                        disabled={
                          (progressNoteState !== ProgressNoteState.EDITING &&
                            progressNoteState !==
                              ProgressNoteState.ADDENDUM_EDITING) ||
                          !notePermissions.includes(
                            ProgressNotePermissions.WRITE
                          )
                        }
                      />
                    ) : null}
                  </>
                )}
            </Form>
            {!!nextTemplateType && (
              <Modal
                title="Discard saved changes?"
                isOpen={!!nextTemplateType}
                onDismiss={() => setNextTemplateType(undefined)}
              >
                <ModalContent>
                  <BodyText>
                    Switching to a different template will discard any changes
                    made to the current template.
                  </BodyText>
                </ModalContent>
                <ModalFooter>
                  <Button onPress={() => setNextTemplateType(undefined)}>
                    Cancel
                  </Button>
                  <Button
                    onPress={() => {
                      setTemplate(nextTemplateType, setFieldValue);
                      setNextTemplateType(undefined);
                    }}
                  >
                    Discard
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

const CreateHeadwayTemplateGuidanceCard = ({
  setProviderClosedConsiderHeadwayTemplateCard,
  provider,
  sendHeadwayTemplateAnalyticsEvent,
  progressNoteType,
}: {
  setProviderClosedConsiderHeadwayTemplateCard: (changeTo: string) => void;
  provider: ProviderRead;
  sendHeadwayTemplateAnalyticsEvent: (
    progressNoteType: ProgressNoteType
  ) => void;
  progressNoteType: ProgressNoteType;
}) => (
  <GuidanceCard
    title={'For your next session, I recommend a Headway template'}
    variant="neutral"
    layout="vertical"
  >
    <BodyText>
      <>
        <div css={{ marginTop: theme.spacing.x4 }}>
          Headway's structured templates help you save time and meet insurance
          compliance standards. In fact, we've seen that progress notes created
          with Headway templates{' '}
          <strong>meet all insurance requirements 3 times more often</strong>{' '}
          than notes created using free text.
        </div>

        <div css={{ marginTop: theme.spacing.x6 }}>
          Prefer to keep your notes organized on another platform? You can
          download your notes created with Headway templates and upload them to
          a HIPAA compliant record-keeping platform.
        </div>

        <div css={{ marginTop: theme.spacing.x6 }}>
          If you're interested in using Headway templates for your next session
          and have any questions, please feel free to{' '}
          <Link
            href="https://calendly.com/office-hours-h2k"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackEvent({
                name: 'Schedule a Meeting Link Clicked',
                properties: {
                  providerId: provider.id,
                },
              });
            }}
          >
            schedule a meeting
          </Link>{' '}
          with a member of our team.
        </div>
        <div css={{ marginTop: theme.spacing.x6 }}>
          The clinical team at Headway
        </div>
      </>
    </BodyText>
    <Button
      onPress={() => {
        sendHeadwayTemplateAnalyticsEvent(progressNoteType);
        setProviderClosedConsiderHeadwayTemplateCard('true');
      }}
    >
      OK
    </Button>
  </GuidanceCard>
);

const SuggestionGuidanceCard = ({
  isUploadOrNote,
}: {
  isUploadOrNote?: boolean;
}) => (
  <GuidanceCard
    title={'A few items we recommend you double check'}
    variant="neutral"
    layout="vertical"
  >
    <BodyText>
      <>
        We recommend double checking that your current note includes the
        information below. These are the items that we've seen insurance
        companies most commonly flag during audits:
      </>
      <div css={{ marginTop: theme.spacing.x6 }}>
        <ol css={{ paddingLeft: theme.spacing.x4 }}>
          <li>
            <strong>Reference the client's current diagnosis,</strong> including
            what information continues to support it or why it has changed
          </li>
          <li>
            <strong>Reference the treatment plan,</strong> with commentary on
            progress toward the overall goal
            <ol type="a">
              <li>
                "Scott's treatment plan lays out a goal to reduce anxiety at
                work and in his social life/environments. Progress today:
                regressing."
              </li>
            </ol>
          </li>
          <li>
            <strong>Indicate whether symptoms</strong> have improved, maintained
            or escalated
          </li>
          {isUploadOrNote && (
            <>
              <li>
                <strong>Include a risk assessment,</strong> and if a risk is
                identified, a safety plan
              </li>

              <li>
                <strong>Include a mental status exam,</strong> with at least 3
                categories present
              </li>
            </>
          )}
        </ol>
      </div>
      <>
        <div css={{ marginTop: theme.spacing.x6 }}>
          <Link
            href="https://findheadway.zendesk.com/hc/en-us/articles/14367706608916"
            target="_blank"
            rel="noreferrer"
          >
            Our team has put together other commonly missed items here.
          </Link>
        </div>
        <div css={{ marginTop: theme.spacing.x6 }}>
          The clinical team at Headway
        </div>
      </>
    </BodyText>
  </GuidanceCard>
);
