import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Skeleton } from '@mui/material';
import HelloSign from 'hellosign-embedded';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { PageHeader } from '@headway/helix/PageHeader';
import { theme } from '@headway/helix/theme';

import { useQuestionnaireContext } from '../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../QuestionnaireV2Step';

const helloSignClient = new HelloSign({
  clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
});

const CompletedForm: React.FC<React.PropsWithChildren<{}>> = () => {
  return (
    <div
      css={{
        borderRadius: 3,
        width: '100%',
        height: 120,
        border: `1px solid ${theme.color.system.borderGray}`,
        background: 'white',
        textTransform: 'none',
        margin: `${theme.spacing.x4} 0`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CheckCircleOutlineIcon
        css={{
          marginBottom: theme.spacing.x2,
        }}
      />
      <p>Release form signed.</p>
    </div>
  );
};

const AndrosForm: React.FC<
  React.PropsWithChildren<{
    questionnaireId: number;
    onComplete: (signature_request_id: any, signature_id: any) => void;
  }>
> = ({ questionnaireId, onComplete }) => {
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const releaseContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadReleaseForm = async () => {
      const { url, signatureRequestId } =
        await ProviderQuestionnaireApi.createQuestionnaireReleaseForm(
          questionnaireId
        );

      helloSignClient.open(url, {
        skipDomainVerification:
          process.env.REACT_APP_ENVIRONMENT !== 'production',
        allowCancel: false,
        hideHeader: true,
        container: releaseContainerRef.current!,
      });
      helloSignClient.on('ready', () => {
        setCreatingTemplate(true);
      });
      helloSignClient.on('sign', (signData) => {
        onComplete(signatureRequestId, signData.signatureId);
      });
    };

    loadReleaseForm();
  }, [onComplete, questionnaireId]);

  return (
    <div>
      {!creatingTemplate && (
        <React.Fragment>
          <Skeleton variant="rectangular" height={100} />
        </React.Fragment>
      )}
      <div
        ref={releaseContainerRef}
        css={{ iframe: { display: 'block', minHeight: 700 } }}
      />
    </div>
  );
};

export const Release = ({
  initialValues,
  updateQuestionnaire,
  existingQuestionnaireData,
  currentStep,
  setCurrentStep,
}: {
  initialValues: ProviderQuestionnaireRawData;
  updateQuestionnaire: (
    oldData: ProviderQuestionnaireRawData,
    newData: ProviderQuestionnaireRawData
  ) => void;
  existingQuestionnaireData: any;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
}) => {
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [values, setValues] = useState<ProviderQuestionnaireRawData>({});
  const { providerQuestionnaire } = useQuestionnaireContext();

  const onStepGoBack = useCallback(() => {
    return setCurrentStep(currentStep - 1);
  }, [currentStep, setCurrentStep]);

  const onStepComplete = useCallback(
    async (stepData: ProviderQuestionnaireRawData) => {
      await updateQuestionnaire(
        existingQuestionnaireData.rawData || {},
        stepData
      );
      setCurrentStep(currentStep + 1);
      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0;
      }
    },
    [
      updateQuestionnaire,
      currentStep,
      setCurrentStep,
      existingQuestionnaireData.rawData,
    ]
  );

  return useMemo(() => {
    return (
      <>
        <h1>
          <PageHeader>{stepConfig.title}</PageHeader>
        </h1>
        <p>
          <BodyText>{stepConfig.description}</BodyText>
        </p>
        <div ref={scrollToRef}>
          <GuidanceCard variant="info">
            <BodyText>
              <strong>Need Help?</strong> Reach out to Headway at{' '}
              <Link
                href="https://headway.co/contact"
                target="_blank"
                rel="noreferrer"
              >
                our contact form
              </Link>
              .
            </BodyText>
          </GuidanceCard>
        </div>
        <div
          css={{
            marginTop: '16px',
            borderTop: `1px solid ${theme.color.system.borderGray}`,
            padding: '24px 16px',
          }}
        >
          <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
            <div
              css={{
                backround: theme.color.system.white,
                overflow: 'hidden',
              }}
            >
              {values?.symplrReleaseSignatureId ? (
                <CompletedForm />
              ) : (
                <AndrosForm
                  questionnaireId={providerQuestionnaire.id}
                  onComplete={(signature_request_id, signature_id) => {
                    setValues({
                      symplrReleaseSignatureId: signature_id,
                      symplrSignatureRequestId: signature_request_id,
                    });
                  }}
                  data-testid="symplrForm"
                />
              )}
            </div>
          </div>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: theme.spacing.x4,
              marginTop: theme.spacing.x4,
              gap: theme.spacing.x2,
              borderTop: `1px solid ${theme.color.system.borderGray}`,
            }}
          >
            <Button
              variant="secondary"
              type="button"
              onPress={() => {
                onStepGoBack();
              }}
            >
              Back
            </Button>
            <Button
              type="submit"
              onPress={() => {
                if (
                  !!values.symplrReleaseSignatureId &&
                  !!values.symplrSignatureRequestId
                ) {
                  onStepComplete(values);
                } else {
                  scrollToRef.current?.scrollIntoView();
                }
              }}
            >
              Save and Continue
            </Button>
          </div>
        </div>
      </>
    );
  }, [providerQuestionnaire.id, values, onStepComplete, onStepGoBack]);
};

export const stepConfig: QuestionnaireV2Step = {
  title: 'Release Forms',
  description: (
    <p>
      In this section, we ask you to sign an attestation and release form,
      attesting to the accuracy and completeness of the information you provided
      in this application. We may also ask you to sign forms that are specific
      to the payers in your state.
      <ul
        css={{
          paddingInlineStart: theme.spacing.x5,
        }}
      >
        <li>
          To start, please make sure to hit the “Get Started” button in the top
          right corner.
        </li>
        <li>
          After this, please review all responses, ensure that the information
          is accurate and that you’ve signed all required fields.
        </li>
        <li>
          If the release includes a Disclosure explanation box you will need to
          fill that out as well. If you checked no to all the disclosure
          questions, you can write “not applicable”, but this field must still
          be filled out in order for you to proceed.
        </li>
      </ul>
    </p>
  ),
  Component: Release,
  getFormMeta: () => ({}) as FormMeta,
};
